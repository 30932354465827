import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Vellore Institute of Technology": {
      jobTitle: "Undergraduate Researcher @",
      duration: "JAN 2021 - MAY 2022",
      desc: [
        "Trained a CNN-RF model, processing 17,000+ hours of Rainforest Audio in real time.",
        "Deployed an alert system with recycled phones, instantly detecting threats such as chainsaws and trucks.",
        "Surpassed benchmarks with a 92.7% accuracy, notably higher than AlexNet (88.7%) and ResNet-50 (88.5%)."
      ],
    },
    "FreshToHome": {
      jobTitle: "Software Engineer, Intern @",
      duration: "FEB 2021 - MAY 2021",
      desc: [
        "Optimized a recommendation engine using RNN models, enhancing user experience for 1.5 million active users.",
        "Implemented real-time data solutions with Redis Streams and Apache Spark, achieving sub-20ms latency.",
        "Collaborated with teams to integrate the recommendation engine, boosting user engagement and session duration."
      ],
    },
    "IEEE VIT Vellore": {
      jobTitle: "Vice-Chairperson @",
      duration: "JAN 2021 - DEC 2021",
      desc: [
        "Led a cross-functional team of 80 students in technical projects, establishing open communication channels to foster collaboration.",
        "Organized 5 hackathons and 36 workshops on Machine Learning and Web Development, impacting 1,000+ students each semester."
      ],
    },
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
